import React from "react";
import {Box, Flex, Heading, Text, Button} from "@chakra-ui/react";
import { Trans, Link } from "gatsby-plugin-react-i18next";
const ContactIndex = () => {

    return (
        <Box
            mb={10}
            pb={10}
            width={'full'}
            position="relative"
            display="block"
            pt={"50px"}
        >
            <Flex alignItems="center" justifyContent="center"  margin={'auto'}>
                <Box pos={'relative'} padding="10px" width={'3xl'} display="flex" flexWrap="wrap" alignItems="center" justifyContent="center" gap="20px">
                    <Heading as={'h2'} textAlign="center" fontSize="32px" mb={'1.5rem'}>
                        <Trans>Need Financial Advisor For Your Business? Contact Us Today</Trans>

                    </Heading>
                    <Text textAlign="center">
                        <Trans>Contact Index Description</Trans>
                    </Text>
                    <Button as={Link} to={'/contact'} backgroundColor="green.600" color="white" width="180px" height="45px" _hover={{ backgroundColor: "teal.600" }}>
                        <Trans>Contact</Trans>
                    </Button>
                </Box>
            </Flex>
        </Box>
    )
};

export default ContactIndex