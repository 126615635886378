import React from "react";
import { graphql } from 'gatsby'
import { Box, Flex, Heading, Text, Container } from "@chakra-ui/react"
import bgImage from "../images/background-overay.jpg";
import Main from "../components/layouts/Main";
import Advantages from "../components/advantages/index";
import AboutUsComponent from "../components/aboutUs/aboutUs";
import Service from "../components/service/service";
import ContactIndex from "../components/contact/contact";
import { FaCheckDouble, FaMedal, FaRegHandshake } from "react-icons/fa";
import { Trans, useTranslation, useI18next } from "gatsby-plugin-react-i18next";
import ReplaceText from "../components/replaceText";
import Seo from "../components/seo";
import SeoLogo from "../images/seo-logo.png"
const advantages = [
    {
        "language": "hy",
        "tree": [
            {
                title: "Մեր առաքելությունը",
                description: "Պրոֆեսիոնալ կազմակերպել ցանկացած ոլորտի ընկերության հաշվապահական հաշվառումը, օպտիմալացնելով ծախսերը և այդ ամենը կազմակերպել գործող օրենսդրության համաձայն, վերցնելով ռիսկերը մեր վրա։",
                icon: <FaMedal/>
            },
            {
                title: "Մեր մոտեցումը",
                description: "Կազմակերպել ընդհանուր հաշվապահությունը այնպես, որ պատվիրատուի վրա որևէ ավելորդ բեռ չհանդիսանա։ Աշխատանքների կատարման ընթացքում միշտ հասանելի պատվիրատուի համար ներառյալ իրավաբանական խորհրդատվությունը։",
                icon: <FaCheckDouble/>
            },
            {
                title: "Ինչ ենք առաջարկում",
                description: "Կազմակերպում ենք հաշվապահական հաշվառումը, շուկայում գործող ամենամատչելի գնով, իսկ իրավաբանական խորհրդատվությունը անվճար",
                icon: <FaRegHandshake/>
            }
        ],
    },
    {
        "language": "ru",
        "tree": [
            {
                title: "Наша миссия ",
                description: "Самые доступные цены на рынке по организации бухгалтерского учета,а так же проводим бесплатную юридическую консультацию для наших клиентов.",
                icon: <FaMedal/>
            },
            {
                title: "Наш подход ",
                description: "Организовать бухгалтерский учет так, чтобы не было лишней нагрузки на клиента.Мы всегда доступны для заказчика при выполнении работ, включая юридические консультации",
                icon: <FaCheckDouble/>
            },
            {
                title: "Что мы предлагаем? ",
                description: "Организовать бухгалтерский учёт компаний разных отраслей. Оптимизировать и организовать затраты согласно действующему законодательству,устраняя вас от дальнейших рисков.",
                icon: <FaRegHandshake/>
            }
        ]
    }
];
const HomePage = () => {
    const {t} = useTranslation();
    const { language } = useI18next()
    const data = advantages.filter((item => item.language === language))[0].tree;
    const url = language === 'hy' ? 'https://solidaccount.am' : `https://solidaccount.am/${language}`
    return (
        <Main>
            <Seo title={t('Home')} url={url} logo={SeoLogo}/>
            <Box
                mt={4}
                width={'full'}
                bgImage={bgImage}
                backgroundPosition="center center"
                backgroundRepeat="nor-repeat"
                backgroundSize="cover"
                position="relative"
                display="block"
                pt={{base: '100px', lg: '200px'}}
                pb={{base: '100px', lg: '300px'}}>
                <Box
                    backgroundColor={'black.400'}
                    opacity="0.7"
                    w="100%"
                    height="100%"
                    top="0"
                    left="0"
                    position="absolute"
                    borderRadius="inherit"
                />
                <Container maxW="8xl">
                    <Flex alignItems="center" width={{base: '100%'}} margin={'auto'} pl={3}>
                        <Box pos={'relative'} paddingRight="70px" width={{ base: "100%", md: "2xl" }}>
                            <Heading as={'h1'} color={'rgba(255, 255, 255, 1)'} fontSize={{ base: "28px", sm: '32px' }}>
                                <ReplaceText text={t('Content Title')} />
                            </Heading>
                            <Text fontSize={'xl'} color={'rgba(255, 255, 255, 1)'} pt={10}>
                                <Trans>Content Description</Trans>
                            </Text>
                        </Box>
                    </Flex>
                </Container>

            </Box>
            <Container maxW={'8xl'}>
                <Flex alignItems={{base: 'center', sm: 'stretch'}}
                      flexDirection={{base: 'column', sm: "row", lg: 'row'}} justifyContent="center" width={'full'}
                      pos={'relative'} gap={6} mt={{base: "-40px", sm: "-60px", md: "-60px", lg: "-120", xl: "-120px"}}>
                    {data?.map((item, idx) => (
                        <Advantages
                            key={`advantages-${idx}`}
                            title={item.title}
                            description={item.description}
                            icon={item.icon}
                        />

                    ))}
                </Flex>
                <AboutUsComponent/>
            </Container>
            <Service background/>
            <ContactIndex/>
        </Main>
    )
}

export default HomePage

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;