import React from "react";
import {Box, Heading, Text, Button, useDisclosure} from "@chakra-ui/react"
import { FaArrowRight } from "react-icons/fa";
import { Trans, Link }    from "gatsby-plugin-react-i18next";
const ServiceBox = (
    {
        title,
        description,
        moreButton
    }
) => {
    const { onOpen } = useDisclosure();
    return (
        <Box
            width={{ base: "320px", sm: "370px" }}
            height="330px"
            padding="50px 40px"
            backgroundColor="rgba(255, 255, 255, 1)"
            borderRadius="10px"
            borderStyle="none"
            borderBottomWidth="8px"
            borderBottomColor="rgba(43, 123, 123, 1)"
            boxShadow="0px 3px 15px 0px rgb(16 16 16 / 10%)"
            pos="relative"
            textAlign="center"
            justifyContent="center"
            border="1px solid #eaeaeb"
            overflow="hidden"
        >
            <Heading as={'h6'} fontSize={'2xl'} mb={'1.5rem'}>
                {title}
            </Heading>
            <Text>
                {description}
            </Text>
            {!!moreButton &&
                <Button
                    as={Link}
                    to={'/services'}
                    onClick={onOpen}
                    borderRadius="0 0 24px 24px"
                    w="full"
                    color="green.600"
                    textAlign="center"
                    background="none !important"
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    pos={'absolute'}
                    bottom={'16'}
                    left={0}
                    pb={4}
                    pt={6}
                >
                    <Trans>See All</Trans> <FaArrowRight style={{display: 'inline-block', marginLeft: "5px"}}/>
                </Button>}
        </Box>

    )
};

export default ServiceBox

