import React from "react";
import {Box, Heading, Text} from "@chakra-ui/react"
const Advantages = (
    {
        icon,
        title,
        description,
    }
) => {
    return (
        <Box
            width={{ base: "320px", sm: "370px" }}
            height="365px"
            padding="17px 28px"
            backgroundColor="rgba(255, 255, 255, 1)"
            borderRadius="10px"
            borderStyle="none"
            boxShadow="0px 3px 15px 0px rgb(16 16 16 / 10%)"
            pos="relative"
            textAlign="center"
            justifyContent="center"
            overflow="hidden"
            transition="all 0.5s cubic-bezier(.25,.8,.25,1)"
            _hover={{
                boxShadow:"0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22)",
            }}
        >
            <Box
                fontSize="5xl"
                width="70px"
                height="70px"
                margin="auto"
                display="flex"
                alignItems="center"
                justifyContent="center"
                borderRadius="50px"
                backgroundColor="green.600"
                color="white"

            >
                {icon}
            </Box>
            <Heading as={'h6'} fontSize={'2xl'} mb={'1.5rem'}>
                {title}
            </Heading>
            <Text>
                {description}
            </Text>
        </Box>

    )
};

export default Advantages
