import React from "react";
import bgImageService from "../../images/service-bg.jpg";
import {Box, Flex, Heading, Text} from "@chakra-ui/react";
import ServiceBox from "./serviceBox";
import {Trans, useTranslation} from "gatsby-plugin-react-i18next";
import serviceData from "../../components/service/services";
const Service = ({ background, from }) => {
    const { t } = useTranslation()
    return (
        <Box
            mb={10}
            pb={10}
            width={'full'}
            bgImage={background && bgImageService}
            backgroundPosition="center center"
            backgroundRepeat="nor-repeat"
            backgroundSize="cover"
            position="relative"
            display="block"
            pt={"50px"}
        >
            {background && <Box
                backgroundColor={'black.400'}
                opacity="0.7"
                w="100%"
                height="100%"
                top="0"
                left="0"
                position="absolute"
                borderRadius="inherit"
            />}
            <Flex alignItems="center" justifyContent="center"  margin={'auto'}>
                <Box pos={'relative'} padding="10px" width={'lg'}>
                    <Heading as={'h5'} color={"green.600"} textAlign="center" fontSize="18px" mb={'1.5rem'}>
                        <Trans>Our Service</Trans>
                    </Heading>
                    <Heading as={'h2'} textAlign="center" color={background && 'white'} fontSize={{ base: "27px", sm: "32px" }} mb={'1.5rem'}>
                        <Trans>What Service We Offer</Trans>
                    </Heading>
                    {from !== 'service' && <Text textAlign="center" color={background && 'white'}>
                        <Trans>Our Service Description</Trans>
                    </Text>}
                </Box>
            </Flex>
            {from === 'service' ? (
                <Box width="80%" margin="auto">
                    {serviceData?.map((item, idx) => (
                        <Box
                            key={`service-single-${item.title}-${idx}`}
                            mb="20px"
                            backgroundColor="rgba(255, 255, 255, 1)"
                            padding="15px 20px"
                            borderRadius="10px"
                            borderStyle="none"
                            boxShadow="0px 3px 15px 0px rgb(1 1 1 / 8%)"
                            fontWeight={500}
                        >
                            {idx + 1}. <Trans>{item.description}</Trans>
                        </Box>
                    ))}
                </Box>
            ) : (
                <Flex alignItems={{ base: 'center', sm: 'stretch' }} flexDirection={{ base: 'column', sm: "row", lg: 'row' }} p={5} justifyContent="center" width={'full'} pos={'relative'} gap={6} mt={10}>
                    {serviceData?.slice(0,3).map((item, idx) => (
                        <ServiceBox
                            key={`service-${item.description}-${idx}`}
                            moreButton title={t(item.title)}
                            description={t(item.description)}
                        />
                    ))}
                </Flex>
            )}

        </Box>
    )
};

export default Service